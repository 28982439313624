import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import ProcessadorPage from "./pages/ProcessadorPage";
import ProtectedRoute from "./components/ProtectedRoute";

const App = () => {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<LoginPage />} />
                <Route element={<ProtectedRoute />}>
                    <Route path="/processador" element={<ProcessadorPage />} />
                </Route>
            </Routes>
        </Router>
    );
};

export default App;
